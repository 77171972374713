/* You can add global styles to this file, and also import other style files */

html, body {
  background-color: #f7f7f7 !important;
    margin: 0px;
    min-height: 100%;
    height: 100%;
  }
  
  * {
    box-sizing: border-box;
  }

  #loginModal{
    position: absolute;
    top: 0;
    background: #f7f7f7;
    width: 100%;
    height: 100%;
    z-index: 9000;
}

.editableCell {
  background-color:#f1f1f1;
}

.prescriptionCell {
  background-color:#eceff1;
}

.login-modal {
  width: 330px;
  margin: auto auto;
  padding: 40px;
}

.discount-number-box {
  margin-left: 10px;
}

.custom-text-box{
  width: 100%;
  /* width: calc(100% - 40px); */
  display:inline-block;
}

.custom-add {
  position: absolute;
  border-style: none;
  right: 20px;
  top: 0px;
  vertical-align: initial;
}

.popupHeader{
  padding: 0 !important;
  height: 42px !important;
}

.row{
  display: flex;
}

.popup-header-items {
  padding: 7px;
  justify-content: flex-end;
  display: flex;
  width: 65%;
}

.scanRecipeButton {
  width: auto;
  margin-right: 10px;
}

.closeButton dx-button {
  border:none;
}

.popupText {
  margin: 12px 0 0 10px;
  width: 35%;
  height: 25px;
  font-size: 20px;
  font-weight: bold;
}

.totalCount {
  font-size: 16px;
  margin-right: 10px;
}

.total-receipt {
  width: 20%;
  height: 132px;
  text-align: center;
  margin-top: 20px;
}

.total-invoice {
  width: 100%;
  height: 47px;
  text-align: center;
  padding-top: 15px;
}


.dx-overlay-wrapper.dx-popup-wrapper.dx-overlay-modal.dx-overlay-shader{
  z-index: 1502 !important;
}
.dx-toolbar.dx-popup-title .dx-toolbar-before{
    width: 90%;
}

.pre-rec-details {
  padding: 10px 75px 0 60px;
  display: flex;
}

.receipt-details {
  /* width: calc(50vw -  200px); */
  min-width: 360px;
}

.search-field input[type=text] {
  text-align: center;
  font-weight: 500;
  font-size: 17px;
}

.search-field .dx-placeholder {
  text-align: center;
  width: 100%;
  font-size: 17px;
} 

.prescription-header {
  padding: 10px 5px;
  width: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  background-color: #fff;
  margin-bottom: 10px;
}

.prescription-diagnostics {
  width: 100%;
  padding: 5px 10px;
   -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  background-color: #fff;
  margin-bottom: 10px;
}

.prescription-accordion {
  padding: 10px 5px;
  width: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); 
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  background-color: #fff;
}

.prescription-accordion .medicalUnitList .dx-list-item {
  padding: 7px 0;
}

.patientTypeList {
  height: 205px;
}

.prescription-accordion .patientTypeList .dx-list-item {
  width: 50%;
  padding: 0;
  float: left;
}

.prescription-accordion .patientTypeList .dx-list-item-content {
  padding: 3px 0;
}

.prescription-accordion .patientTypeList .dx-list-item:first-of-type {
  margin-top: 0;
}

.scan-box {
   border: 15px dashed #e9e9e9;
   position: absolute;
   width: 450px;
   height: 250px; 
   background-color: white;
   display: flex;
   justify-content: center; 
   align-items: center;
}

.scan-text-description {
  color: #e9e9e9;
  font-size: 40px !important;
}

#scan-text-box {
  width: 100px;
  outline: 0;
  z-index: -1;
}

ul.wizard, ul.wizard li {
  margin: 2px 0 0;
  padding: 0;
}

ul.wizard {
  counter-reset: num;
}

ul.wizard li {
  list-style-type: none;
  float: left;
  position: relative;
  text-align: center;
}

ul.wizard li::before {
  display: block;
  counter-increment: num;
  content: counter(num);
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 1.5em;
  border-radius: 50%;
  background: #9fcf68;
  margin: 0 auto;
}
ul.wizard li ~ li::after {
  content: '';
  position: absolute;
  width: 100%;
  right: 50%;
  height: 4px;
  background-color: #9fcf68;
  top: calc(.75em - 2px);
  z-index: -1;
}
ul.wizard li.active::before {
  background: #317928;
  color: white;
}
ul.wizard .active ~ li::before,
ul.wizard .active ~ li::after {
  background: #b7b7b7;
}
ul.wizard span {
  display: inline-block;
  color: #7d7d7d;
  font-size: 12px;
  font-family: Arial;
}

.wizardHeader {
  height: 45px !important;
}

.wizardCloseButton {
  right: 10px;
  top: 3px;
  cursor: pointer;
  position: absolute;
}

.wizardCloseButton dx-button {
  border:none;
}

.payment-method .dx-list-item {
    width: 50%;
    padding: 0;
    float: left;
  }
  
